import Dialog, { CloseButton, Header } from "../../style/Dialog"
import React, { useState } from "react"
import styled, { x } from "@xstyled/styled-components"
import useSWR, { mutate } from "swr"

import Button from "../../style/Button"
import { ErrorText } from "../../style/Text"
import { FieldResponseType } from "../../customTypes"
import InfoBox from "../../style/InfoBox"
import Input from "../../style/Input"
import Label from "../../style/Label"
import { post } from "../../utils/request"
import type { CustomField } from "../../types"

type Props = {
  fighterUid: string
  eventUid: string
  onClose: () => void
  responseType: FieldResponseType
  showDescription?: boolean
}

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Des = styled.p`
  white-space: pre-wrap;
`

type Custom = { [name: string]: string }
const formatCustomFields = (data: Custom): Custom[] =>
  Object.entries(data).map(([name, value]) => ({ name, value }))

const addMetaInfoToCustomFields = (
  userFields: Custom[],
  tournamentFields: CustomField[] = [],
  responseType: FieldResponseType,
) => {
  return userFields.map((u) => {
    const { name, value } = u
    const matchingField = tournamentFields.find(
      (t) => t.name === name && t.responseType === responseType,
    )
    return {
      name,
      value,
      private: matchingField?.private || true,
      responseType,
    }
  })
}

const isRequiredCustomFieldsValid = (
  userFields: Custom[],
  tournamentFields: CustomField[] = [],
  responseType: FieldResponseType,
) => {
  if (tournamentFields.length === 0) return true

  const missingFields = tournamentFields.filter(
    (t) =>
      t.responseType === responseType &&
      t.required &&
      !userFields.some((u) => u.name === t.name && u.value),
  )

  return missingFields.length === 0
}

const Register: React.FC<Props> = ({
  fighterUid,
  eventUid,
  onClose,
  responseType,
  showDescription,
}) => {
  const { data } = useSWR<Tournament>(`event/${eventUid}`)
  const [customFields, setCustomFields] = useState<Custom>({})
  const [validate, setValidate] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const isSignUp = responseType === "accept"
  const { title, description } = data || {}
  const register = async () => {
    setValidate(false)
    setError(null)

    const formattedCustomFields = formatCustomFields(customFields)

    if (
      !isRequiredCustomFieldsValid(
        formattedCustomFields,
        data?.customFields,
        responseType,
      )
    ) {
      setValidate(true)
      return
    }
    setInProgress(true)
    const payload = {
      fighterUid,
      eventUid,
      attending: isSignUp,
      customFields: addMetaInfoToCustomFields(
        formattedCustomFields,
        data?.customFields,
        responseType,
      ),
    }

    try {
      await post("event/rsvp", payload)
      mutate("event")
      mutate(`event/${eventUid}/fighters`)
      mutate(`event/${eventUid}/admin/registrations`)
      mutate(`event/${eventUid}/registrations`)

      setInProgress(false)
      onClose()
    } catch (error) {
      console.log(error)
      setInProgress(false)
      setError(error)
    }
  }

  const handleCustomOption = (name: string, value: string) => {
    setCustomFields((v) => ({ ...v, [name]: value }))
  }

  const renderCustomField = (
    { name, type, private: required }: CustomField,
    index: number,
  ) => {
    // const options =
    //   (selectValues && selectValues.map((v) => ({ value: v, label: v }))) || []
    return (
      <Item key={index}>
        <Label>{name}</Label>
        <Input
          type={type}
          value={customFields[name] || ""}
          onChange={(e) => handleCustomOption(name, e.target.value)}
          // select={type === 'select'}
          // options={options}
          // helperText={required ? 'Påkrævet' : ''}
          error={validate && !!required && !customFields[name]}
        />
      </Item>
    )
  }

  return (
    <Dialog aria-label="sign up" onDismiss={inProgress ? undefined : onClose}>
      <x.div display="flex" justifyContent="space-between">
        {isSignUp ? (
          <Header>Tilmelding til {title}</Header>
        ) : (
          <Header>Framelding til {title}</Header>
        )}
        {!inProgress && <CloseButton onClick={onClose} />}
      </x.div>
      <x.div p="2">
        {showDescription && description && (
          <InfoBox my="3" mx="1">
            <Des>{description}</Des>
          </InfoBox>
        )}

        {data?.customFields
          .filter((c) => c.responseType === responseType)
          .map(renderCustomField)}
      </x.div>
      <x.div p="3" display="flex" justifyContent="flex-end">
        <Button mr="3" variant="link" onClick={onClose} disabled={inProgress}>
          Fortryd
        </Button>
        <Button variant="primary" onClick={register} loading={inProgress}>
          {isSignUp ? "Tilmeld" : "Frameld"}
        </Button>
      </x.div>
      {error && <ErrorText>{error?.message}</ErrorText>}
    </Dialog>
  )
}

export default Register
