import styled from '@xstyled/styled-components'
import {
  space,
  layout,
  SpaceProps,
  LayoutProps,
  background,
  BackgroundProps,
} from '@xstyled/system'

const Input = styled.input<
  { error?: boolean } & LayoutProps & SpaceProps & BackgroundProps
>`
  ${space}
  ${layout}
  ${background}
  border-radius: 3;
  padding: 2;
  border: solid 1px;
  border-color: ${(p) =>
    p.error ? p.theme.colors.error : p.theme.colors.border};
  box-sizing: border-box;
  
  
`
export default Input
