import { Grid, Item } from "../EventOverview/EventTable"
import React, { Fragment, useMemo, useState } from "react"
import styled, { useDown, x } from "@xstyled/styled-components"
import Button from "../../style/Button"
import FilterButton from "../../features/FilterButton"
import Flex from "../../style/Flex"
import { Link } from "raviger"
import Loading from "../../features/Loading"
import { MdModeEdit } from "react-icons/md"
import Text from "../../style/Text"
import Title from "../../style/Title"
import useSWR from "swr"

const Capitalize = styled.span`
  text-transform: capitalize;
`

const EditLink = styled(Link)`
  margin-right: 2;
`

const FighterTitle = styled.h3`
  margin: 0 2 0 0;
  font-size: 3;
  font-weight: normal;
`
type Categories = {
  [key: string]: Fighter[]
}

const sortOrder = [
  "",
  "a-kæmper",
  "b-kæmper",
  "udviklingsgruppe",
  "bruttolandsholdet",
  "landsholdet",
  "coach",
]
const sortStatus = (a: string, b: string): number =>
  sortOrder.indexOf(a) - sortOrder.indexOf(b)

const categories: Categories = {
  aspirant: [],
  cadet: [],
  junior: [],
  senior: [],
  none: [],
}
const Fighters: React.FC = () => {
  const downMd = useDown("md")

  const { data: user } = useSWR<User>("auth/me")
  const { data, error } = useSWR<Fighter[]>("fighter")
  const [filter, setFilter] = useState<Filters>(null)

  const categorizedFighters = useMemo(
    () =>
      (data || [])
        ?.sort((a, b) => sortStatus(a.status || "", b.status || ""))
        .reduce((total, current) => {
          const { ageCategory } = current
          const { isAdmin } = user || {}

          if (current.status === "coach" && !isAdmin) return total

          total[ageCategory || "none"].push(current)
          return total
        }, categories),
    [data, user],
  )

  const renderDesktopFighter = (fighter: Fighter) => {
    const { uid, name, club, status, weightClass } = fighter
    return (
      <Fragment key={uid}>
        <Item>
          {user?.isAdmin && (
            <EditLink href={`/fighters/${uid}`}>
              <MdModeEdit />
            </EditLink>
          )}
          {name}
        </Item>
        <Item>{club}</Item>
        <Item>{weightClass}</Item>
        <Item>
          <Capitalize>{status}</Capitalize>
        </Item>
      </Fragment>
    )
  }
  const renderMobileFighter = (fighter: Fighter) => {
    const { uid, name, club, status, weightClass } = fighter
    return (
      <x.div
        key={uid}
        borderBottom="1px solid"
        px="2"
        py="3"
        borderColor="border"
      >
        <Flex justifyContent="space-between">
          <FighterTitle>{name}</FighterTitle>
          {user?.isAdmin && (
            <EditLink href={`/fighters/${uid}`}>
              <MdModeEdit />
            </EditLink>
          )}
        </Flex>

        <Text m="0">{club}</Text>
        <Text m="0">
          <Capitalize>{status}</Capitalize>
        </Text>
        <Text m="0">{weightClass}</Text>
      </x.div>
    )
  }

  const renderCategory = (name: string, category: AgeCategory | "none") => {
    if (filter && filter !== category) return null
    if (!categorizedFighters[category].length) return null
    return (
      <x.div my="4">
        {name ? <Title>{name}</Title> : <x.div height="3rem" />}
        {downMd ? (
          categorizedFighters[category].map(renderMobileFighter)
        ) : (
          <Grid columns="2fr 2fr 1fr 2fr">
            {categorizedFighters[category].map(renderDesktopFighter)}
          </Grid>
        )}
      </x.div>
    )
  }

  if (error) return <Text>{error.message}</Text>
  if (!data) return <Loading />

  return (
    <>
      <Flex
        justifyContent={{ xs: "center", md: "space-between" }}
        flexWrap="wrap"
      >
        <x.div mb={{ xs: 2, md: 0 }}>
          <FilterButton onSelect={setFilter} selected={filter} />
        </x.div>
        {user?.isAdmin && (
          <Link href="/fighters/create">
            <Button variant="primary">Opret kæmper</Button>
          </Link>
        )}
      </Flex>
      {renderCategory("Udviklingsgruppe", "aspirant")}
      {renderCategory("Kadetter", "cadet")}
      {renderCategory("Juniorer", "junior")}
      {renderCategory("Seniorer", "senior")}
      {renderCategory("", "none")}
    </>
  )
}

export default Fighters
