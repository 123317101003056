import { Link as A, navigate } from 'raviger'
import React, { ReactElement, useMemo, useState } from 'react'
import { Table, Th, Tr } from '../../style/table'
import Text, { ErrorText } from '../../style/Text'
import { useDown, x } from '@xstyled/styled-components'

import Button from '../../style/Button'
import { FiChevronRight } from 'react-icons/fi'
import { FieldResponseType } from '../../customTypes'
import Flex from '../../style/Flex'
import Register from '../Event/Register'
import { Spinner } from '../../features/Loading'
import Title from '../../style/Title'
import formatDateTime from '../../utils/formatDateTime'
import formatRange from '../../utils/formatRange'
import styled from '@xstyled/styled-components'
import useSWR from 'swr'
import type { Fighter, Tournament } from '../../types'

const EventTitle = styled.h3`
  margin: 0 1rem 0 0;
  font-size: 3;
`
const EventLink = styled(A)`
  color: text;
  text-decoration: none;
`

type Props = {}
type TournamentFighter = Tournament & {
  fighters: {
    uid: string
    attending: boolean
    invited: boolean
    answered: boolean
  }[]
}

const MyEvents: React.FC<Props> = () => {
  const { data: fighters, error: fighterError } =
    useSWR<Fighter[]>('users/fighters')
  const downMd = useDown('md')
  const { data, error } = useSWR<TournamentFighter[]>('event')
  const [eventUid, setEventUid] = useState<null | string>(null)
  const [fighterUid, setFighterUid] = useState<null | string>(null)
  const [responseType, setResponseType] = useState<null | FieldResponseType>(
    null,
  )

  const openModal = (
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    eventUid: string,
    fighterUid: string,
    responseType: FieldResponseType,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    setEventUid(eventUid)
    setFighterUid(fighterUid)
    setResponseType(responseType)
  }
  const closeModal = () => {
    setEventUid(null)
    setFighterUid(null)
    setResponseType(null)
  }

  const sortedData =
    useMemo(
      () => data?.sort((a, b) => a.start.getTime() - b.start.getTime()),
      [data],
    ) || []

  const renderStatus = (
    eventUid: string,
    fighterUid: string,
    answered: boolean,
    attending: boolean,
    answerRequired: boolean,
    close: Date,
  ) => {
    if (answered) return attending ? 'Tilmeldt' : 'Frameldt'

    if (close < new Date()) return 'Lukket'

    return (
      <>
        <Button
          p="0"
          variant="link"
          onClick={(e) => openModal(e, eventUid, fighterUid, 'accept')}
        >
          Tilmeld
        </Button>
        {answerRequired && (
          <Button
            px="0"
            ml="3"
            variant="link"
            onClick={(e) => openModal(e, eventUid, fighterUid, 'decline')}
          >
            Frameld
          </Button>
        )}
      </>
    )
  }

  const renderDesktopEvent = (
    {
      uid,
      title,
      close,
      end,
      start,
      fighters,
      answerRequired,
    }: TournamentFighter,
    fighterUid: string,
  ): ReactElement | null => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const fighter = fighters?.find((f) => f.uid === fighterUid)
    if (!fighter) return null

    return (
      <Tr key={uid} cursor="pointer" onClick={() => navigate(`/events/${uid}`)}>
        <td>{formatRange(startDate, endDate)} </td>
        <td>{title} </td>
        <td>{formatDateTime(close)}</td>
        <td>
          {renderStatus(
            uid,
            fighterUid,
            fighter.answered,
            fighter.attending,
            answerRequired,
            close,
          )}
        </td>
        <td>
          <FiChevronRight />
        </td>
      </Tr>
    )
  }

  const renderMobileEvent = (
    {
      uid,
      title,
      close,
      end,
      start,
      fighters,
      answerRequired,
    }: TournamentFighter,
    fighterUid: string,
  ): ReactElement | null => {
    const fighter = fighters?.find((f) => f.uid === fighterUid)
    if (!fighter) return null
    return (
      <EventLink key={uid} href={`/events/${uid}`}>
        <x.div
          borderBottom="1px solid"
          px="2"
          pb="2"
          pt="3"
          borderColor="border"
        >
          <Flex alignItems="center" flexWrap="wrap">
            <EventTitle>{title}</EventTitle>
            <Text fontSize="2" my="0">
              {formatRange(start, end)}
            </Text>
          </Flex>
          <Text m="0">{`Tilmeldingsfrist ${formatDateTime(close)}`}</Text>
          <Flex mt="2" justifyContent="start">
            {renderStatus(
              uid,
              fighterUid,
              fighter.answered,
              fighter.attending,
              answerRequired,
              close,
            )}
          </Flex>
        </x.div>
      </EventLink>
    )
  }

  const renderEvents = ({ name, uid }: Fighter) => {
    const events = sortedData.filter((event) =>
      event.fighters.some((f) => f.uid === uid),
    )
    return (
      <x.div py="4" key={uid}>
        <Title>{name}</Title>
        {!data && (
          <Flex p="3" justifyContent="center">
            <Spinner />
          </Flex>
        )}

        {data && events.length === 0 && (
          <Text textAlign="center" color="faded" p="3">
            {name} er ikke udtaget til nogle turneringer
          </Text>
        )}

        {
          !!events.length &&
            // <ResponsiveTable
            //   narrow={downMd}
            //   style={{ width: "100%", borderCollapse: "collapse" }}
            // >
            (downMd ? (
              <>{events.map((d) => renderMobileEvent(d, uid))}</>
            ) : (
              <Table>
                <thead>
                  <tr>
                    <Th>Dato</Th>
                    <Th>Begivenhed</Th>
                    <Th>Tilmeldingsfrist</Th>
                    <Th>Status</Th>
                  </tr>
                </thead>
                <tbody>{events.map((d) => renderDesktopEvent(d, uid))}</tbody>
              </Table>
            ))
          // </ResponsiveTable>
        }
      </x.div>
    )
  }

  return (
    <>
      {fighterError && <ErrorText mt="6">{fighterError.message}</ErrorText>}
      {error && <ErrorText mt="6">{error.message}</ErrorText>}
      {fighters?.length === 0 && (
        <Text mt="6" fontSize="3" textAlign="center">
          Du har ingen kæmpere tilknyttet din profil
        </Text>
      )}
      {fighters?.map(renderEvents)}
      {fighterUid && eventUid && responseType && (
        <Register
          fighterUid={fighterUid}
          eventUid={eventUid}
          onClose={closeModal}
          responseType={responseType}
          showDescription
        />
      )}
    </>
  )
}

export default MyEvents
