import React from "react"
import ReactLinkify from "react-linkify"

const Linkify: React.FC = ({ children }) => {
  return (
    <ReactLinkify
      componentDecorator={(
        decoratedHref: string,
        decoratedText: string,
        key: number,
      ) => (
        <a
          target="blank"
          rel="noopener noreferrer"
          href={decoratedHref}
          key={key}
        >
          {decoratedText}
        </a>
      )}
    >
      {children}
    </ReactLinkify>
  )
}

export default Linkify
