import styled, { x } from "@xstyled/styled-components"
import { MdClose } from "react-icons/md"
import React, { useEffect, useRef, type ReactNode } from "react"

export const Header = styled.h2`
  margin: 3;
  font-weight: 400;
`

export const CloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <x.button
      backgroundColor="transparent"
      border="none"
      transition="color 300ms"
      cursor="pointer"
      onClick={onClick}
    >
      <MdClose aria-hidden size={25} />
    </x.button>
  )
}

const Dialog: React.FC<{
  children: ReactNode
  onDismiss: undefined | (() => void)
  "aria-label": string
}> = ({ children, onDismiss, "aria-label": ariaLabel }) => {
  const ref = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    const handleEscapeClose = (e: KeyboardEvent) =>
      !onDismiss && e.key === "Escape" && e.preventDefault()

    const handleClose = () => onDismiss?.()

    ref.current?.showModal()

    ref.current?.addEventListener("close", handleClose)
    ref.current?.addEventListener("keydown", handleEscapeClose)
    return () => {
      ref.current?.removeEventListener("keydown", handleEscapeClose)
      ref.current?.removeEventListener("close", handleClose)
    }
  }, [onDismiss])

  return (
    <x.dialog
      boxSizing="border-box"
      margin={{ xs: 0, md: "10vh auto" }}
      w={{ xs: "100%", md: "fit-content" }}
      minWidth={{
        xs: "100%",
        md: "600px",
      }}
      maxWidth={{ xs: "100%", md: "60vw" }}
      h={{ xs: "100%", md: "fit-content" }}
      minHeight={{ xs: "100%", md: "fit-content" }}
      aria-label={ariaLabel}
      border="none"
      ref={ref}
    >
      {children}
    </x.dialog>
  )
}

export default Dialog
